<template>
  <div class="w-full h-full pt-5">
    <div class="flex">
      <back-button
        label="Back"
        @onClick="$router.back()"
        variant="secondary"
        class="mr-4"
      />
      <div
        class="mt-4"
        style="border-left: 1px solid #878e99; height: 1.5rem"
      />
      <h1 class="text-xl text-left font-extrabold ml-4 mt-3 w-auto">
        Employees
      </h1>
      <div class="w-7/12 mt-4 ml-8">
        <Breadcrumb :items="breadcrumbs" />
      </div>
    </div>
    <div style="height: 100%" class="mt-20" v-if="loading">
      <loader size="xxs" :loader-image="false" />
    </div>
    <div class="px-3" v-if="!loading">
      <card class="p-5 mt-4 flex flex-col">
        <div class="w-full flex mb-4">
          <span class="text-sm font-semibold text-romanSilver uppercase">
            Employee :
          </span>
          <span class="text-sm font-semibold ml-1">
            {{ ` ${employee.fname} ${employee.lname}` }}
          </span>
        </div>
        <div class="w-full flex">
          <img
            v-if="employee.photo !== '' && employee.photo !== null"
            class="mr-3 rounded"
            style="width: 135px; height: 128px"
            :src="employee.photo"
            alt="profile"
          />
          <div
            style="height: 138px; width: 138px; border-radius: 5px"
            class="text-blueCrayola border text-center font-black text-lg pt-14 mr-2"
            v-else
          >
            {{ $getInitials(`${employee.fname} ${employee.lname}`) }}
          </div>
          <div class="w-full flex flex-col">
            <div class="w-full flex">
              <card class="flex p-3 mb-4 w-auto mr-3 flex-grow">
                <div class="flex flex-col">
                  <div class="flex">
                    <icon icon-name="icon-suitcase" size="xs" />
                    <span class="ml-1 text-carrotOrange text-xs">
                      Designation
                    </span>
                  </div>
                  <div class="text-xs ml-5 mt-1 font-semibold uppercase">
                    {{ employee.designation }}
                  </div>
                </div>
              </card>
              <card class="flex p-3 mb-4 mr-3 w-auto flex-grow">
                <div class="flex flex-col">
                  <div class="flex">
                    <icon icon-name="icon-department" size="xs" />
                    <span class="ml-1 text-blueCrayola text-xs">
                      Function
                    </span>
                  </div>
                  <div class="text-xs ml-5 mt-1 font-semibold uppercase">
                    {{ employee.function }}
                  </div>
                </div>
              </card>
              <card class="flex p-3 w-auto mb-4 mr-3 flex-grow">
                <div class="flex flex-col">
                  <div class="flex">
                    <icon icon-name="line-manager" color="#FFFFFF" size="xs" />
                    <span class="ml-1 text-blueCrayola text-xs">
                      Line Manager
                    </span>
                  </div>
                  <div class="text-xs ml-5 mt-1 font-semibold uppercase">
                    {{ employee.lineManager.fname }}
                    {{ employee.lineManager.lname }}
                  </div>
                </div>
              </card>
            </div>
            <div class="w-full flex">
              <card class="flex p-3 mr-3 w-auto mb-4 flex-grow">
                <div class="flex flex-col">
                  <div class="flex">
                    <icon
                      icon-name="icon-user-list"
                      color="#FFFFFF"
                      size="xs"
                    />
                    <span class="ml-1 text-blueCrayola text-xs">
                      Employee ID
                    </span>
                  </div>
                  <div class="text-xs ml-5 mt-1 font-semibold uppercase">
                    {{ employee.employeeId }}
                  </div>
                </div>
              </card>
              <card class="flex p-3 mb-4 mr-3 flex-grow">
                <div class="flex flex-col">
                  <div class="flex">
                    <icon icon-name="map-pin" size="xms" />
                    <span class="ml-2 text-dome text-xs">
                      Office Location
                    </span>
                  </div>
                  <div class="text-xs ml-5 mt-1 font-semibold uppercase">
                    {{ employee.location }}
                  </div>
                </div>
              </card>
            </div>
          </div>
        </div>
      </card>
    </div>

    <ValidationObserver rules="required" v-slot="{ handleSubmit }">
      <div class="px-3" v-if="!loading">
        <card class="p-5" style="margin-top: 4px">
          <div
            class="w-full flex flex-row"
            style="margin-top: 20px; flex-grow: 1"
          >
            <div class="w-9/12 h-auto mt-2">
              <h1 class="text-base font-bold">Agreement Details</h1>
            </div>

            <div class="h-full w-auto flex flex-grow">
              <div class="p-2 w-full flex">
                <div
                  class="flex w-full justify-end ml-10 -mt-2"
                  v-if="payload.status === 'active' && !approvedSubmit"
                >
                  <Button
                    class="text-dynamicBackBtn border border-solid border-dynamicBackBtn text-center text-sm"
                    width="12rem"
                    height="2rem"
                    @click="approveAgreement()"
                  >
                    Approve and Submit
                  </Button>
                </div>

                <div class="flex w-full justify-end ml-10 -mt-2">
                  <Button
                    class="text-white bg-mediumSeaGreen text-center text-sm"
                    width="12rem"
                    height="2rem"
                    v-if="approvedSubmit"
                  >
                    Submitted for Appraisal
                  </Button>
                </div>

                <Button
                  class="text-center font-semibold flex p-1 -mt-1 text-base ml-5 -mr-10"
                  width="12rem"
                  height="1rem"
                  @click="commentModal = true"
                >
                  <div class="flex">
                    <Icon
                      icon-name="icon-message-outline"
                      class-name="mr-2 w-5 h-5"
                      size="xsm"
                    />
                    Comments
                  </div>
                </Button>
              </div>
            </div>
          </div>

          <div style="border-bottom: 1px solid #878e99; height: 1rem" />

          <div class="flex flex-col w-full my-4">
            <!----- List of KPA's ---->

            <div class="w-full h-auto flex flex-col">
              <accordion
                class="mx-3 mt-6"
                v-for="(kpaTypes, kpaNumber) in templateKPATypes"
                :key="kpaNumber"
                show-divider
                @open="handleOpen($event, kpaTypes.id)"
                :is-open="true"
              >
                <template v-slot:title>
                  <span class="font-bold">{{ kpaTypes.name }}</span>
                </template>
                <div class="w-full">
                  <ValidationObserver>
                    <scroll-container height="1000px">
                      <Table
                        :headers="KPAHeader"
                        :items="kpaTypes.kpas"
                        class="w-full h-full my-2 table"
                        aria-label="absence table"
                        :has-number="false"
                      >
                        <template v-slot:item="{ item }">
                          <div v-if="item.state">
                            <div v-if="item.data.state" class="p-2">
                              <div class="flex flex-col">
                                <div
                                  class="cursor-pointer"
                                  v-if="item.data.isLocked || editingLock"
                                  @click="
                                    lockKPA(item.data.isLocked, item.data)
                                  "
                                >
                                  <icon
                                    icon-name="icon-lock"
                                    size="xs"
                                    class="my-2"
                                  />
                                </div>
                                <div
                                  class="cursor-pointer"
                                  v-else
                                  @click="
                                    lockKPA(item.data.isLocked, item.data)
                                  "
                                >
                                  <icon
                                    icon-name="icon-unlock"
                                    size="xs"
                                    class="my-2"
                                  />
                                </div>
                                <div
                                  class="cursor-pointer"
                                  @click="
                                    deleteKPA(
                                      item.index,
                                      kpaTypes,
                                      item.data.kpaId
                                    )
                                  "
                                  v-if="!item.data.isLocked && !editingLock"
                                >
                                  <icon
                                    icon-name="icon-trash"
                                    size="xs"
                                    class="my-2"
                                  />
                                </div>
                                <div v-else />
                              </div>
                            </div>
                          </div>

                          <div v-else-if="item.name">
                            <div class="label">
                              <c-text
                                placeholder="--Enter KPI--"
                                variant="w-full"
                                class="mr-2 -ml-1 -mt-1 w-72"
                                :disabled="item.data.isLocked || editingLock"
                                v-model="item.data.name"
                                v-tooltip.bottom-middle="item.data.name"
                                :rules="inputRules"
                                label="KPI"
                              />
                            </div>
                          </div>

                          <div v-else-if="item.objective">
                            <div class="label">
                              <c-text
                                placeholder="--Enter Objective--"
                                variant="w-full"
                                class="mr-2 -ml-1 -mt-2 w-72"
                                :disabled="item.data.isLocked || editingLock"
                                v-model="item.data.objective"
                                v-tooltip.bottom-middle="item.data.objective"
                                :rules="inputRules"
                                label="Objective"
                              />
                            </div>
                          </div>

                          <div v-else-if="item.kpis" class="flex">
                            <div
                              class="label flex"
                              v-for="(n, rating) in item.data.kpis"
                              :key="rating"
                            >
                              <ValidationObserver>
                                <c-text
                                  variant="w-full"
                                  :class="`mr-2 -mt-2 w-48`"
                                  :placeholder="`Criteria ${rating}`"
                                  :disabled="item.data.isLocked || editingLock"
                                  v-model="item.data.kpis[rating].criteria"
                                  v-tooltip.bottom-middle="
                                    item.data.kpis[rating].criteria
                                  "
                                  :rules="inputRules"
                                  :label="`Criteria ${rating} value`"
                                />
                              </ValidationObserver>
                            </div>
                          </div>

                          <div v-else-if="item.target_date">
                            <div class="label w-72">
                              <div
                                class="date-flex -mt-2"
                                :class="
                                  item.data.isLocked || editingLock
                                    ? 'bg-disabled'
                                    : ''
                                "
                              >
                                <datepicker
                                  placeholder="--Select Day & Month & Year--"
                                  style="width: 100%; outline: none"
                                  input-class="date-input"
                                  format="yyyy-MMMM-dd"
                                  v-model="item.data.target_date"
                                  v-tooltip.bottom-middle="
                                    item.data.target_date
                                  "
                                  :disabled="item.data.isLocked || editingLock"
                                />
                              </div>
                            </div>
                          </div>

                          <div v-else-if="item.weight">
                            <div class="label">
                              <c-text
                                placeholder="--Enter Weight--"
                                variant="w-full"
                                class="mr-2 -ml-1 -mt-2 w-24"
                                type="number"
                                min="0"
                                max="100"
                                :rules="weightRules"
                                v-model="item.data.weight"
                                v-tooltip.bottom-middle="item.data.weight"
                                :disabled="item.data.isLocked || editingLock"
                                label="Weight"
                              />
                            </div>
                          </div>
                        </template>
                      </Table>
                    </scroll-container>
                  </ValidationObserver>
                </div>
              </accordion>
            </div>
          </div>
        </card>
      </div>

      <div
        class="pl-3 flex mt-5"
        v-if="!approvedSubmit && payload.status !== 'draft'"
      >
        <div class="flex flex-grow">
          <Button
            :disabled="disableBtn"
            :class="disableBtn ? `btn-disabled` : `bg-dynamicBackBtn text-white`"
            @click="handleSubmit(checkSelected('Submit'))"
          >
            <span class="mr-6 ml-6">Update</span>
          </Button>

          <Button
            background-color="none"
            class="ml-4"
            style="color: #321c3b"
            @click="$router.back()"
          >
            Cancel
          </Button>
        </div>
      </div>
    </ValidationObserver>

    <RightSideBar
      v-if="commentModal"
      @submit="sendComment"
      @close="commentModal = false"
      submit="Save"
      button-class="bg-dynamicBackBtn text-white"
    >
      <template v-slot:title>
        <span class="text-darkPurple font-bold text-lg leading-5 p-0"
          >Comments</span
        >
      </template>
      <div class="w-full flex">
        <div class="flex flex-col w-full" style="min-height: 70vh">
          <div class="flex flex-col mb-3">
            <div
              class="w-full flex flex-col items-center"
              v-if="allComments.length === 0"
            >
              <icon icon-name="empty_comments" size="xl" style="width: 450" />
            </div>
            <div
              class="w-full flex flex-col"
              v-for="(item, index) in allComments"
              :key="index"
              v-else
            >
              <div class="w-full flex">
                <div>
                  <img
                    class="mr-3"
                    :src="item.sender.photo"
                    v-if="item.sender.photo"
                    alt="user photo"
                    style="height: 40px; width: 40px; border-radius: 5px"
                  />
                  <div
                    style="height: 40px; width: 40px; border-radius: 5px"
                    class="text-blueCrayola mr-3 flex justify-center border text-center font-semibold pt-1"
                    v-else
                  >
                    {{
                      $getInitials(`${item.sender.fname} ${item.sender.lname}`)
                    }}
                  </div>
                </div>
                <div
                  class="flex flex-col whitespace-nowrap font-semibold text-sm leading-5"
                >
                  <span class="text-darkPurple capitalize">
                    {{ `${item.sender.fname} ${item.sender.lname}` }}
                  </span>
                  <span class="flex text-xs font-semibold text-romanSilver">
                    {{ item.sender.designation }}
                  </span>
                </div>
              </div>

              <div class="my-2 w-full border p-4" style="border-radius: 5px">
                <span class="text-base">
                  {{ item.message }}
                </span>
              </div>

              <div class="p-1 w-full flex">
                <span class="text-xs font-semibold text-romanSilver uppercse">
                  {{ item.time }}
                </span>
                <span
                  :class="
                    item.isRead
                      ? `text-mediumSeaGreen`
                      : `text-desire cursor-pointer`
                  "
                  class="text-xs font-semibold uppercase ml-2"
                  @click="!item.isRead ? markAsRead(item.commentId) : ''"
                >
                  {{ item.isRead ? `•  Read` : `•  Unread` }}
                </span>
              </div>
            </div>
          </div>
          <div class="-mb-12" style="margin-top: auto">
            <CTextarea
              placeholder="--Message--"
              variant="primary"
              class="mt-2"
              value=""
              :row="8"
              :height="90"
              :col="30"
              :input="null"
              v-model="comment"
            />
          </div>
        </div>
      </div>
    </RightSideBar>
  </div>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import Datepicker from "vuejs-datepicker";
import BackButton from "@scelloo/cloudenly-ui/src/components/back-button";
import Breadcrumb from "@scelloo/cloudenly-ui/src/components/bread-crumb";
import CText from "@scelloo/cloudenly-ui/src/components/text";
import CTextarea from "@scelloo/cloudenly-ui/src/components/text-area";
import Table from "@scelloo/cloudenly-ui/src/components/table";
import Card from "@/components/Card";
import Button from "@/components/Button";
import ScrollContainer from "@/components/ScrollContainer";
import Accordion from "@/components/Accordion";
import RightSideBar from "@/components/RightSideBar";
import timeFormatMixin from "@/utilities/timeFormatMixin";

export default {
  mixins: [timeFormatMixin],
  components: {
    ValidationObserver,
    BackButton,
    Breadcrumb,
    CTextarea,
    RightSideBar,
    Card,
    Datepicker,
    CText,
    Table,
    Button,
    ScrollContainer,
    Accordion,
  },
  data() {
    return {
      loading: true,
      loadingTable: true,
      commentModal: false,
      comment: "",
      allComments: [],
      breadcrumbs: [
        {
          disabled: false,
          text: "Performance Agreements",
          href: "Performance Agreements",
          id: "Performance Agreements",
        },
        {
          disabled: false,
          text: `${this.$route.params.type}`,
          href: `${this.$route.params.type}`,
          id: `${this.$route.params.type}`,
        },
      ],
      KPAHeader: [
        { title: "•", value: "state" },
        { title: "KPI", value: "name" },
        { title: "Objective", value: "objective" },
        { title: "Criteria", value: "kpis" },
        { title: "Target Date", value: "target_date" },
        { title: "Weight (%)", value: "weight" },
      ],
      inputRules: [
        "required",
        {
          name: "data",
          rule: (w) => this.validateEntries(w),
        },
      ],
      functions: [],
      employee: [],
      templateKPATypes: [],
      editingLock: false,
      weightRules: [
        "required",
        {
          name: "weight",
          rule: (w) => this.validateWeight(w),
        },
      ],
      employeeKPI: [],
      Ratings: [],
      kpaData: [],
      validateKPA: [],
      kpaDetails: [],
      kpaName: [],
      approvedSubmit: false,
      managerLock: false,
      disableBtn: false,
      payload: {
        orgId: "",
        templateId: "",
        title: "",
        goalId: "",
        status: "draft",
        created_by: "",
        created_for: "",
        share_for_review: false,
        kpa_types: [],
      },
    };
  },

  methods: {
    lockKPA(isLocked, data) {
      if (this.managerLock) {
        // eslint-disable-next-line no-param-reassign
        data.isLocked = !isLocked;
      } else {
        this.$toasted.error(
          "You do not have the privilege to lock or unlock an agreement",
          { duration: 5000 }
        );
      }
    },

    deleteKPA(index, kpaTypes, id) {
      if(kpaTypes.kpas.length > 1) {
      if (!this.approvedSubmit) {
        kpaTypes.kpas.splice(index, 1);

        this.$_deleteAgreementKPI(id).then((response) => {
          this.$toasted.success(response.data.message, { duration: 5000 });
        });
      }
      } else {
        this.$toasted.error('Cannot delete ALL KPIs of a KPA Type, you can edit KPI values instead', {duration: 5000});
      }
    },

    formatCommentTime(date) {
      const newDate = new Date(date);
      const hours = newDate.getUTCHours();
      const minutes = newDate.getMinutes();

      return `${1 + ((hours - 1) % 12)}:${minutes
        .toString()
        .padStart(2, "0")} ${hours > 11 ? "PM" : "AM"}`;
    },

    getComments(agreementId) {
      this.allComments = [];
      this.$_getAgreementComments(agreementId).then((response) => {
        const { comments } = response.data;

        comments.forEach((comment) => {
          this.allComments.push({
            message: comment.comment,
            time: this.formatCommentTime(comment.date),
            commentId: comment.id,
            isRead: comment.readStatus,
            receiver: comment.receiver,
            sender: comment.sender,
          });
        });
      });
    },

    sendComment() {
      const payload = {
        performanceAgreementId: this.$route.params.agreement,
        senderId: this.$AuthUser.id,
        receiverId: this.$route.params.id,
        comment: this.comment,
        sendTo: "admin",
      };

      this.$_postAgreementComments(payload).then((response) => {
        this.$toasted.success(response.data.message, { duration: 5000 });
        this.getComments(this.$route.params.agreement);
        this.comment = "";
      });
    },

    markAsRead(commentId) {
      const payload = {
        commentId,
        readStatus: true,
      };

      this.$_markAsRead(payload)
        .then((response) => {
          this.$toasted.success(response.data.message, { duration: 5000 });
          this.getComments(this.$route.params.agreement);
        })
        .catch((err) => {
          this.$toasted.error(err.response.data.message, { duration: 5000 });
        });
    },

    getAgreements() {
      this.$_viewEmployeeAgreements(this.$route.params.id).then((response) => {
        this.loading = false;

        const myAgreements = response.data.EmployeePerformanceAgreements;

        this.employee = response.data.user;

        myAgreements.filter((aggreement) => {
          if (aggreement.id === this.$route.params.agreement) {
            this.payload.templateId = aggreement.templateId;
            this.payload.agreementId = aggreement.id;
            this.payload.title = aggreement.title;
            this.payload.agreementId = this.$route.params.agreement;
            this.payload.status = aggreement.status;
            this.approvedSubmit = aggreement.approveAndSubmit;
            this.payload.created_for = aggreement.created_for;
            this.payload.created_by = aggreement.created_by;

            if (aggreement.created_for !== aggreement.created_by) {
              this.managerView = true;
            }

            const agreementKPA = aggreement.employee_agreement_kpa;

            agreementKPA.forEach((kpa) => {
              this.kpaDetails.push(kpa);
            });

            setTimeout(() => {
              this.setAgreementData();
              this.getSettings();
            }, 1000);

            /* this.$_getOneTemplate(aggreement.templateId).then((result) => {
              this.templateKPATypes = result.data.PerformanceTemplate.kpa_types;

              const appraisalCycles =
                result.data.PerformanceTemplate.appraisal_cycle;

              this.payload.title = appraisalCycles.title;

              this.templateKPATypes.forEach((kpaType) => {
                const kpaData = kpaType;

                const newKPAs = [];

                const isFound = agreementKPA.some(
                  (el) => el.kpaTypeId === kpaType.id
                );

                if (!isFound) {
                  if (kpaType.kpas.length > 0) {
                    kpaType.kpas.map((kpasData) => {
                      newKPAs.push({
                        state: true,
                        isLocked: false,
                        name: kpasData.name,
                        objective: kpasData.objective,
                        kpis: kpasData.kpi,
                        targetDate: null,
                        weight: kpasData.weight,
                      });
                      return {};
                    });

                    kpaData.kpas = newKPAs;
                  } else {
                    kpaType.kpas.push({
                      state: true,
                      isLocked: false,
                      name: null,
                      objective: null,
                      kpis: this.getCriteriaData(),
                      targetDate: null,
                      weight: "",
                    });
                  }
                } else {
                  agreementKPA.filter((employeeKpi) => {
                    if (employeeKpi.kpaTypeId === kpaType.id) {
                      kpaType.kpas.push({
                        state: true,
                        isLocked: false,
                        name: employeeKpi.name,
                        objective: employeeKpi.objective,
                        kpis: this.getCriteriaData(employeeKpi.kpis_employee),
                        targetDate: employeeKpi.targetDate,
                        weight: employeeKpi.weight,
                      });
                    }
                    return {};
                  });
                }
              });
            }); */
          }
          return {};
        });
      });
    },

    resetDate(value) {
      if (value) {
        const dateValue = value;

        const split = dateValue.split("T00:00:00.000Z");

        return `${split[0]}T13:00:00.000Z`;
      }
      return "";
    },

    setAgreementData() {
      this.kpaDetails.map((detail) => {
        if (this.kpaName.includes(detail.kpa_type.id)) {
          this.templateKPATypes.filter((kpaType) => {
            if (kpaType.id === detail.kpa_type.id) {
              kpaType.kpas.push({
                state: true,
                isLocked: detail.isLocked,
                name: detail.name,
                objective: detail.objective,
                kpaId: detail.id,
                kpis: this.getCriteriaData(detail.kpis_employee),
                target_date: this.resetDate(detail.targetDate),
                weight: detail.weight,
              });
            }
            return {};
          });
        } else {
          this.templateKPATypes.push({
            id: detail.kpa_type.id,
            name: detail.kpa_type.name,
            kpas: [
              {
                state: true,
                isLocked: detail.isLocked,
                name: detail.name,
                objective: detail.objective,
                kpaId: detail.id,
                kpis: this.getCriteriaData(detail.kpis_employee),
                target_date: this.resetDate(detail.targetDate),
                weight: detail.weight,
              },
            ],
          });

          this.kpaName.push(detail.kpa_type.id);
        }
        return {};
      });
    },

    validateWeight(weight) {
      let sum = 0;
      if (weight < 0) {
        return "Value must be greater than or equal to 0";
      }

      this.templateKPATypes.forEach((kpaType) => {
        kpaType.kpas.map((kpi) => {
          sum += Number(kpi.weight);
          return {};
        });
        return {};
      });

      if (sum > 100) {
        return "Total allocated weight for all KPA's cannot be greater than 100%";
      }

      this.allocatedWeight = sum;

      return true;
    },

    validateEntries(t) {
      if (t !== "") {
        return true;
      }

      return false;
    },

    handleOpen(state) {
      if (state) {
        this.disableBtn = false;
      } else {
        this.disableBtn = true;
      }
    },

    formatEmployeeDate(value) {
      let initialDate = "";

      if (value) {
        initialDate = new Date(value).toISOString().replace(/.\d+Z$/g, "");

        return this.$DATEFORMAT(new Date(initialDate), "MMMM dd, yyyy");
      }

      return "";
    },

    createNewKPA(kpa) {
      const arrayObj = kpa.kpas[0];
      let isEmpty = true;

      if (
        arrayObj.name !== "" &&
        arrayObj.objective !== "" &&
        arrayObj.weight !== ""
      ) {
        // iterate over criteria array
        arrayObj.criteria.forEach((v) => {
          if (Object.keys(v).every((k) => v[k] !== "")) {
            isEmpty = false;
          }
        });
      }

      this.addKPATable(kpa.id, isEmpty);
    },

    addKPATable(kpaId, valid) {
      if (!valid) {
        if (this.allocatedWeight < 100) {
          this.templateKPATypes.forEach((kpaType) => {
            if (kpaType.id === kpaId) {
              kpaType.kpas.push({
                state: true,
                isLocked: false,
                name: null,
                objective: null,
                criteria: this.getCriteriaData(),
                target_date: "",
                weight: "",
              });
            }
            return {};
          });
        } else {
          this.$toasted.error(
            "Total allocated weight for all KPA's cannot be greater than 100%",
            { duration: 5000 }
          );
        }
      }
    },

    approveAgreement() {
      const agreementPayload = {
        agreementId: this.$route.params.agreement,
        approveAndSubmit: true,
      };

      this.$_approveAgreement(agreementPayload)
        .then((response) => {
          this.$toasted.success(response.data.message, { duration: 5000 });
          this.$router.push({
            name: "EssAgrreements",
            query: { activeTab: "My Direct Reports" },
          });
        })
        .catch((error) => {
          this.$toasted.error(error.response.data.message, { duration: 5000 });
        });
    },

    getCriteriaData(kpis) {
      const criteriaData = [];
      const sortedRatings = this.Ratings.sort((a, b) => a.rating - b.rating);
      const sortedKPIS = kpis ? kpis.sort((a, b) => a.score.rating - b.score.rating) : null;

      this.Ratings = sortedRatings;

      if (kpis) {
        this.Ratings.map((rating, index) => {
          criteriaData.push({
            id: sortedKPIS[index].id,
            performanceRatingId: sortedKPIS[index].performanceRatingId,
            criteria: sortedKPIS[index].criteria,
          });
          return {};
        });
      } else {
        this.Ratings.map((rating) => {
          criteriaData.push({
            performanceRatingId: rating.id,
            criteria: "",
          });
          return {};
        });
      }

      return criteriaData;
    },

    submit(status) {
      this.kpaData = [];

      this.templateKPATypes.forEach((kpaType) => {
        const newKPAs = kpaType.kpas.map(({ state, ...rest }) => ({
          ...rest,
        }));

        newKPAs.map((kpi) => {
          const kpiData = kpi;
          kpiData.target_date = this.formatDate(kpiData.target_date, "arrange");

          return {};
        });

        const validation = newKPAs.every(
          (item) => item.name || item.objective || item.weight
        );

        if (validation) {
          this.kpaData.push({
            kpa_types_id: kpaType.id,
            kpas: newKPAs,
          });
        } else {
          this.kpaData.push({
            kpa_types_id: kpaType.id,
            kpas: [],
          });
        }

        return {};
      });

      this.payload.kpa_types = this.kpaData;
      this.payload.orgId = this.$orgId;
      this.payload.status = status;

      this.$_createEmployeeAgreement(this.payload)
        .then((response) => {
          this.$toasted.success(response.data.message, { duration: 5000 });
          this.$router.push({
            name: "EssAgrreements",
            query: { activeTab: "My Direct Reports" },
          });
        })
        .catch((error) => {
          this.$toasted.error(error.response.data.message, { duration: 5000 });
        });
    },

    getFunctions() {
      this.functions = [];
      this.$_getFunctionKpis("").then((response) => {
        response.data.functions.forEach((f) => {
          this.functions.push({
            id: f.id,
            name: f.name,
          });
        });
      });
    },

    checkSelected(value) {
      this.templateKPATypes.forEach((kpaType) => {
        const newKPAs = kpaType.kpas;

        const validation = newKPAs.every(
          (item) => item.name || item.objective || item.weight
        );

        this.validateKPA.push(validation);
      });

      if (this.validateKPA.every((valid) => valid === true)) {
        this.payload.share_for_review = true;
        this.submit("active");
      } else {
        this.validateEntries("");
      }

      return value;
    },

    getSettings() {
      this.$_getPerformanceGeneralSetting().then((response) => {
        const settings = response.data.PerformanceSettings;

        this.managerLock = settings[0].managerCanLockAgreement;

        if (this.approvedSubmit) {
          this.editingLock = settings[0].lockSubmittedAgreement;
        }
      });
    },

    getGoals() {
      this.$_getOrganisationGoal("?filter=agreement").then((response) => {
        const goalData = response.data.goals;
        goalData.forEach((goals) => {
          if (goals.goal.year === Number(this.$route.params.year)) {
            this.payload.goalId = goals.goal.id;
          }
          return {};
        });
      });
    },
  },

  mounted() {
    this.getAgreements();
    this.getFunctions();
    this.getGoals();
    this.$_getPerfomanceRating().then((response) => {
      this.Ratings = response.data.PerformanceRatings;
    });
    this.getComments(this.$route.params.agreement);
  },
};
</script>

<style>
.bg-disabled {
  background-color: #eeeeee;
  border-color: transparent;
}

.cyc-text {
  font-size: 12px;
  font-weight: 900;
  line-height: 1.3rem;
  color: #878e99;
}

.inner-text {
  font-size: 12px;
  line-height: 1.9rem;
  font-weight: 600;
  text-align: center;
  color: #878e99;
  text-transform: uppercase;
}

.label label {
  display: none;
}

.cycle {
  border: 1px dashed #878e99;
  border-radius: 5px;
  padding: 12px;
}

.cycle__text {
  font-size: 12px;
  font-weight: 900;
  line-height: 1.3rem;
  color: #878e99;
  letter-spacing: 0.035em;
  height: 17px;
}

.fcard {
  padding: 12px;
  height: 58px;
  margin-top: 18px;
  line-height: 16.8px;
  text-transform: uppercase;
}

.fcard__icon {
  margin-right: 7.5px;
}

.csm {
  width: 12px;
  height: 12px;
}

.fcard__header {
  font-weight: 900;
  font-size: 12px;
  margin-top: -2px;
  letter-spacing: 0.035em;
}

.fcard__text {
  font-weight: 600;
  font-size: 14px;
  color: #333333;
  margin-right: 7.5px;
  letter-spacing: 0.015em;
}

.allocate {
  width: 320px;
  border-radius: 5px;
  padding: 8px;
  background-color: #f7f7ff;
  font-weight: 900;
  font-size: 12px;
  letter-spacing: 0.035em;
}

.allocate__text {
  line-height: 1.4rem;
  text-transform: uppercase;
  color: #321c3b;
}

.allocate__value {
  line-height: 17px;
  color: #ea3c53;
  margin: 3px 10px;
}

.line {
  width: 24px;
  height: 0px;
  transform: rotate(90deg);
  margin-left: 6px;
  margin-right: 6px;
}

.line--dashed {
  border: 0.5px dashed #878e99;
}

.line--straight {
  border: 1px solid #878e99;
}

.kpaplus {
  width: 180px;
  border-radius: 5px;
  background-color: #ffffff;
  padding: 10px;
}

.kpaplus__text {
  font-weight: 900;
  font-size: 13px;
  line-height: 17px;
  letter-spacing: 0.035em;
  margin: 3px 10px;
  color: #f15a29;
}

.ksm {
  margin-left: 11px;
  color: #ea3c53;
  width: 14px;
  height: 14px;
  margin-top: 4px;
}

.kpalist__text {
  font-size: 18px;
  font-weight: bold;
  line-height: 120%;
  letter-spacing: 0.015em;
  color: #321c3b;
  width: 170px;
  height: 22px;
}

.kpalisticon {
  width: 18px;
  height: 18px;
}

.button-layout {
  width: 270px;
  height: 44px;
  margin-top: 29px;
}

.btn-disabled {
  background-color: #eeeeee;
  font-size: 16px;
  cursor: disabled;
}

details > summary {
  display: inline;
  list-style: none;
}

.icon-Size {
  width: 200px;
  height: 200px;
}
.illu-text {
  text-align: center;
  font-weight: 400;
  line-height: 19px;
  font-size: 16px;
  letter-spacing: 0.015em;
}
</style>

<style>
.cyc-text {
  font-size: 12px;
  font-weight: 900;
  line-height: 1.3rem;
  color: #878e99;
}

.inner-text {
  font-size: 12px;
  line-height: 1.9rem;
  font-weight: 600;
  text-align: center;
  color: #878e99;
  text-transform: uppercase;
}

.label label {
  display: none;
}

.cycle {
  border: 1px dashed #878e99;
  border-radius: 5px;
  padding: 12px;
}

.cycle__text {
  font-size: 12px;
  font-weight: 900;
  line-height: 1.3rem;
  color: #878e99;
  letter-spacing: 0.035em;
  height: 17px;
}

.fcard {
  padding: 12px;
  height: 58px;
  margin-top: 18px;
  line-height: 16.8px;
  text-transform: uppercase;
}

.fcard__icon {
  margin-right: 7.5px;
}

.csm {
  width: 12px;
  height: 12px;
}

.fcard__header {
  font-weight: 900;
  font-size: 12px;
  margin-top: -2px;
  letter-spacing: 0.035em;
}

.fcard__text {
  font-weight: 600;
  font-size: 14px;
  color: #333333;
  margin-right: 7.5px;
  letter-spacing: 0.015em;
}

.allocate {
  width: 320px;
  border-radius: 5px;
  padding: 8px;
  background-color: #f7f7ff;
  font-weight: 900;
  font-size: 12px;
  letter-spacing: 0.035em;
}

.allocate__text {
  line-height: 1.4rem;
  text-transform: uppercase;
  color: #321c3b;
}

.allocate__value {
  line-height: 17px;
  color: #ea3c53;
  margin: 3px 10px;
}

.line {
  width: 24px;
  height: 0px;
  transform: rotate(90deg);
  margin-left: 6px;
  margin-right: 6px;
}

.line--dashed {
  border: 0.5px dashed #878e99;
}

.line--straight {
  border: 1px solid #878e99;
}

.kpaplus {
  width: 180px;
  border-radius: 5px;
  background-color: #ffffff;
  padding: 10px;
}

.kpaplus__text {
  font-weight: 900;
  font-size: 13px;
  line-height: 17px;
  letter-spacing: 0.035em;
  margin: 3px 10px;
  color: #f15a29;
}

.ksm {
  margin-left: 11px;
  color: #ea3c53;
  width: 14px;
  height: 14px;
  margin-top: 4px;
}

.kpalist__text {
  font-size: 18px;
  font-weight: bold;
  line-height: 120%;
  letter-spacing: 0.015em;
  color: #321c3b;
  width: 170px;
  height: 22px;
}

.kpalisticon {
  width: 18px;
  height: 18px;
}

.button-layout {
  width: 270px;
  height: 44px;
  margin-top: 29px;
}

.btn-disabled {
  background-color: #eeeeee;
  font-size: 16px;
  cursor: disabled;
}

details > summary {
  display: inline;
  list-style: none;
}

.icon-Size {
  width: 200px;
  height: 200px;
}
.illu-text {
  text-align: center;
  font-weight: 400;
  line-height: 19px;
  font-size: 16px;
  letter-spacing: 0.015em;
}
</style>